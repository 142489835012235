.qafana .form-feedback {
  $dismiss-button-size: 24px;

  $info: #4babfd;
  $success: #47ac63;
  $error: #cd4d49;

  background: #eff1f1;
  border: 1px solid $info;
  border-radius: 10px;
  padding: 8px 12px;
  align-items: flex-start;
  justify-content: space-between;

  .message {
    margin: 0;
    font-size: 17px;
    font-weight: 500;
    color: $info;
    max-width: calc(100% - $dismiss-button-size - 15px);
  }

  .dismiss {
    width: $dismiss-button-size;
    height: $dismiss-button-size;
    padding: 0;
    border: none;

    .icon {
      fill: $info;
    }
  }

  &.success {
    border-color: $success;

    .message {
      color: $success;
    }

    .dismiss .icon {
      fill: $success;
    }
  }

  &.error {
    border-color: $error;

    .message {
      color: $error;
    }

    .dismiss .icon {
      fill: $error;
    }
  }
}
