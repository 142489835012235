@use '../../../../variables' as *;

.qafana .page.home section.process-and-culture {
  .grid {
    grid-template-columns: 100%;
    align-items: start;

    @include for-tablet-landscape-up {
      grid-template-columns: 50% 50%;
      grid-template-rows: 40% 20% 40%;
    }
  }

  .background,
  .card {
    grid-column: 1 / 2;
  }

  .background {
    grid-row: 2 / 3;
    width: 121%;
    margin-left: -12%;

    @include for-tablet-landscape-up {
      grid-column: 1 / 2;
      grid-row: 1 / 4;
      width: 100%;
    }
  }

  .card.process {
    grid-row: 1 / 2;

    @include for-tablet-landscape-up {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      align-self: end;
    }

    .description {
      margin-top: 20px;
    }

    .eight-steps {
      margin: 0 {
        top: 32px;
        bottom: 10px;
      };
      font-size: 20px;
      line-height: 30px;

      @include for-tablet-landscape-up {
        font-size: 24px;
        line-height: 36px;
      }

      a {
        display: block;
        text-decoration-line: underline;
        text-decoration-thickness: 3px;

        @include for-tablet-landscape-up {
          display: inline-block;
          margin-left: 10px;
        }
      }
    }
  }

  .card.culture {
    grid-row: 3 / 4;
    margin-top: 45px;

    @include for-tablet-landscape-up {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      align-self: start;
      margin-top: 0;
    }
  }
}
