@use '../../../../variables' as *;

.qafana .page.home {
  section.intro {
    .grid {
      grid-template-columns: 100%;
      align-items: start;

      @include for-tablet-landscape-up {
        grid-template-columns: 50% 50%;
      }
    }

    .background,
    .animation,
    .title,
    .description {
      grid-column: 1 / 2;
    }

    .title {
      grid-row: 1 / 2;
      font-size: 40px;

      @include for-tablet-landscape-up {
        grid-column: 1 / 2;
        font-size: 64px;
        line-height: initial;
      }

      @include for-desktop-up {
        grid-column: 1 / 2;
        font-size: 64px;
      }
    }

    .background {
      grid-row: 2 / 3;
      width: 240%;
      margin-left: -120%;
      margin-top: -5%;
      z-index: -1;

      @include for-tablet-landscape-up {
        grid-row: 1 / 4;
        grid-column: 1 / 3;
        margin: 0;
        width: 106%;
        margin-top: -4%;
      }
    }

    .animation {
      grid-row: 2 / 3;
      transform: scale(0.4);
      align-self: center;
      margin-top: -45%;

      @include for-tablet-landscape-up {
        grid-row: 1 / 4;
        grid-column: 2 / 3;
        transform: scale(0.37);
        align-self: start;
        margin-top: 25%;
      }
    }

    .description {
      grid-row: 4 / 5;

      @include for-tablet-landscape-up {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        margin: 0 {
          top: 20px;
        };
      }
    }

    .gradient {
      display: none;
      height: 100%;

      background: linear-gradient(
        90deg,
        rgba($gray-light, 0.9) 0%,
        rgba($gray-light, 0.85) 80%,
        rgba($gray-light, 0.8) 90%,
        rgba($gray-light, 0) 100%
      );

      @include for-tablet-landscape-up {
        display: block;
        grid-column: 1 / 2;
        grid-row: 1 / 4;
      }
    }

    // .overlay.shadow {
    //   background: none;

    //   @include for-tablet-landscape-up {
    //     background: linear-gradient(
    //         90deg,
    //         rgba($gray-light, 0.9) 0%,
    //         rgba($gray-light, 0.9) 60%,
    //         rgba($gray-light, 0) 70%
    //       );
    //   }

    //   @include for-tablet-landscape-up {
    //     background: linear-gradient(
    //         90deg,
    //         rgba($gray-light, 0.9) 0%,
    //         rgba($gray-light, 0.9) 50%,
    //         rgba($gray-light, 0) 60%
    //       );
    //   }
    // }
  }
}
