@use './variables' as *;
@use 'sass:map';

body, input, button {
  font-family: 'Satoshi-Variable', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  font-size: 17px;
}

body {
  margin: 0;
  background: #F6F6F8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

menu {
  margin: 0;
  padding: 0;
}

h1, h2, h3 {
  font-weight: 700;
}

p {
  line-height: 24px;
}

button {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hidden {
  display: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.top-left {
  top: 0;
  left: 0;
}

.overlay {
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;

  &.wrap {
    flex-wrap: wrap;
  }

  .break {
    flex-basis: 100%;
  }

  .align-baseline {
    align-items: baseline;
  }

  :not(.column) {
    .break {
      height: 0;
    }
  }

  &.column {
    flex-direction: column;

    .break {
      width: 0;
    }
  }

  &.center {
    // Center the elements on the main axis
    justify-content: center;
  }

  &.middle {
    // Center the elements on the cross axis
    align-items: center;
  }
}

.grid {
  display: grid;
}

:root {
  --base-width: #{base-width-fluid(map.get($margins, 'mobile'))};

  @include for-tablet-landscape-up {
    --base-width: #{base-width-fluid(map.get($margins, 'desktop'))};
  }

  @include for-big-desktop-up {
    --base-width: #{$base-width-max};
  }
}

.base-width {
  width: var(--base-width);
}
