@use '../../variables' as *;

.qafana > footer {
  padding: 50px 0;
  color: $green;
  background-color: #19221B;
  margin-top: 100px;

  .btn {
    font-size: 16px;
  }

  .blocks {
    flex-direction: column;

    @include for-tablet-landscape-up {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      gap: 4em;
    }

    @include for-desktop-up {
      gap: 6em;
    }

    .block {
      @include for-tablet-landscape-up {
        padding: 0 20px;
      }

      .title {
        margin: 30px 0;
        font-weight: 500;
        color: #96B499;
        font-size: 20px;
      }

      .content {
        color: $green-light;
        font-size: 12px;
      }

      .content {
        @include for-tablet-landscape-up {
          font-size: 15px;
        }
      }

      .content .btn {
        @include for-tablet-landscape-up {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }

      li {
        list-style-type: none;
        font-weight: 500;
        margin: 14px 0;
      }

      &.services,
      &.company {
        a {
          text-decoration: none;
          color: $green-light;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      &.services nav ul,
      &.company nav {
        margin: 0 0 0 30px;
        padding: 0;

        @include for-tablet-landscape-up {
          margin: 0;
        }
      }

      &.services {
        li:first-child {
          margin-top: 0;
        }

        nav ul {
          @include for-tablet-landscape-up {
            margin-right: 45px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &.main {
        margin-top: 45px;

        @include for-tablet-landscape-up {
          order: -1;
          margin-top: 10px;
        }

        .content {
          display: flex;
          flex-direction: column;
          width: 100%;

          @include for-tablet-landscape-up {
            display: flex;
            flex-direction: column;
          }

          .logo-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include for-tablet-landscape-up {
              flex-direction: column;
              flex-grow: 0;
              padding-right: 100px;
              align-items: start;
            }

            @include for-tablet-landscape-up {
              padding-right: 0;
              padding-bottom: 20px;
            }

            .logo {
              color: #96B499;

              .image {
                min-width: 50px;

                @include for-tablet-landscape-up {
                  min-width: 69px;
                }
              }

              .text {
                margin-left: 8px;
                font-size: 24px;
                color: #96B499;

                @include for-tablet-landscape-up {
                  font-size: 40px;
                }
              }
            }

            .moto {
              font-size: 16px;
              margin: 0;
              width: 30%;

              @include for-tablet-portait-only {
                text-align: right;
                margin-right: 20px;
              }

              @include for-tablet-landscape-up {
                margin-top: 5px;
                width: 100%;
              }
            }
          }

          .button-wrapper {
            margin-top: 40px;

            @include for-tablet-landscape-up {
              margin-top: 12px;
            }

            .btn {
              width: calc(100% - 28px * 2);

              @include for-tablet-landscape-up {
                width: calc(180px - 28px * 2);
              }
            }
          }
        }
      }
    }
    .social-media {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px; // Adjust as needed
  
      a {
        display: inline-block;
        margin: 0 10px;
        color: $green-light; // Use your color variable
  
        &:hover {
          color: $green; // Change on hover
        }
  
        svg {
          width: 50px; // Adjust size as needed
          height: 50px;
        }
      }
    }
  }
}
