@use '../../variables' as *;

.qafana .logo {
  display: inline-flex;
  align-items: center;
  color: $black;

  .image {
    min-width: 36px;
  }

  .text {
    margin: 0;
    margin-left: 5px;
    color: #282828;
  }
}
