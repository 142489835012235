@use '../../../../variables' as *;

.qafana .page.home {
  section.services {
    .grid {
      grid-template-columns: 100%;
      align-items: start;

      @include for-tablet-landscape-up {
        grid-template-columns: 50% 50%;
      }
    }

    .background,
    .title,
    .description,
    .services-list,
    .see-more-wrapper {
      grid-column: 1 / 2;
    }

    .background {
      grid-row: 3 / 4;
      width: 125%;
      margin-left: -15%;
      align-self: center;

      @include for-tablet-landscape-up {
        grid-column: 2 / 3;
        grid-row: 1 / 5;
      }

      @include for-desktop-up {
        align-self: end;
      }
    }

    .title {
      grid-row: 1 / 2;

      @include for-tablet-landscape-up {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
    }

    .description {
      grid-row: 2 / 3;

      @include for-tablet-landscape-up {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
      }
    }

    .services-list {
      grid-row: 4 / 5;
      margin: 0 {
        top: 40px;
      };
      padding: 0;
      flex-direction: column;
      font-weight: 500;
      height: 250px;
      flex-wrap: nowrap;
      overflow: hidden;
      overflow-y: scroll;
      padding-right: 8px;

      @include for-tablet-landscape-up {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        margin-top: 24px;
        height: auto;
        flex-direction: row;
        flex-wrap: wrap;
        padding-right: 0;
        overflow-y: hidden;
      }

      li {
        list-style-type: none;
        padding: 10px 12px;
        border: 1px solid rgba($gray-dark, 0.1);
        background-color: $gray-light;
        margin-bottom: 16px;
        border-radius: 6px;
        text-align: center;
        font-size: 16px;
        text-overflow: ellipsis;

        @include for-tablet-landscape-up {
          width: calc(50% - 50px);
          margin: 8px {
            left: 0;
            right: 16px;
          };
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .see-more-wrapper {
      grid-row: 5 / 6;
      justify-content: center;

      @include for-tablet-landscape-up {
        grid-column: 1 / 2;
        grid-row: 4 / 5;
        justify-content: flex-start;
      }
    }
  }
}
