@use '../../../variables' as *;

.qafana .page.home {
  section h2.title {
    @include for-tablet-landscape-up {
      font-size: 64px;
      line-height: initial;
    }
  }
}
