@use 'variables' as *;

.qafana {
  color: $gray-dark;
  overflow-x: hidden;

  a {
    color: $blue;
  }

  button {
    color: #3d3d3d;
  }

  .compact-scrollbar::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  .compact-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba($gray, 0.5);
  }

  input[type=text],
  input[type=email],
  input[type=file] {
    padding: 12px 14px 12px 16px;
    background: #f7f7f7;
    color: $gray-dark;
    border: 1px solid #bfbfbf;
    border-radius: 10px;
    font-weight: 400;
    display: block;
    width: calc(100% - 34px);
    font-size: 16px;
    line-height: 24px;

    &::placeholder {
      opacity: 1;
      color: #808080;
    }
  }

  input[type=file] {
    padding: 8px 12px 8px 16px;
    color: #808080;
    line-height: 30px;
    cursor: pointer;

    &.rtl {
      text-align: left;

      &::file-selector-button {
        float: right;
        margin: 0 0 0 10px;
      }
    }

    &.with-icon::file-selector-button {
      cursor: pointer;
      background: #ffffff url(./assets/icons/add.svg) no-repeat 3px center;
      background-size: 24px;
      padding-left: 27px;
    }
  }

  input[type=file]::file-selector-button {
    font-weight: 700;
    margin-right: 10px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 0px 12px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);;
    color: #181818;
    background-color: #ffffff;
    line-height: 30px;

    @include for-tablet-landscape-up {
      box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.2);
    }
  }

  input:focus {
    outline: 2px solid #1e7ed0;
  }

  input[type=checkbox].styled {
    display: none;

    + label, &:checked + label {
      display: inline-block;
      cursor: pointer;
      padding: 0;
      width: 24px;
      height: 24px;
      border: 1px solid transparent;
      border-radius: 6px;
      overflow: hidden;
    }

    &:not(:checked) + label {
      border-color: $blue;
    }

    &:checked + label {
      background: url(./assets/icons/checkmark.svg) no-repeat;
    }
  }

  form {
    .group {
      margin-top: 25px;

      &.required > label::after{
        content: "*";
        margin-left: 5px;
        font-weight: 700;
        color: $red;
      }

      label {
        display: block;
        font-weight: 500;
        color: rgba($gray-dark, 0.8);
        margin-bottom: 10px;
        word-break: break-word;
        font-size: 16px;
      }

      &.buttons {
        justify-content: center;

        @include for-tablet-landscape-up {
          justify-content: start;
        }

        button.submit {
          margin-top: 20px;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }
  }

  .page-not-found {
    padding-top: 80px;
    text-align: center;

    @include for-tablet-landscape-up {
      padding: 250px 0 150px 0;
    }

    a.go-to-home {
      font-weight: 500;
    }
  }

  .main .page {
    padding-top: 50px;

    @include for-tablet-landscape-up {
      padding-top: 100px;
    }

    h1.title,
    h2.title {
      color: $gray-dark;
      font-size: 40px;
      margin: 0;
      line-height: 50px;

      @include for-tablet-landscape-up {
        height: auto;
      }

      @include for-tablet-landscape-up {
        font-size: 50px;
        line-height: 70px;
      }
    }

    & > section {
      &:not(:first-child) {
        padding-top: 92px;
      }

      .btn.see-more {
        margin-top: 48px;
        width: 180px;
        text-align: center;
        padding: {
          right: 0;
          left: 0;
        };
      }

      .description {
        color: #5A6066;
        font-size: 17px;
        line-height: 24px;

        @include for-tablet-landscape-up {
          font-size: 17px;
          line-height: 25px;
        }

        .btn.see-more {
          display: block;

          @include for-tablet-landscape-up {
            margin-top: 60px;
          }
        }
      }
    }
  }
}
