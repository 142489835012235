@use '../../../variables' as *;

.qafana .page.team {
  .row {
    flex-direction: column;
    flex-wrap: nowrap;

    @include for-tablet-landscape-up {
      flex-direction: row;
      justify-content: space-between;

      &.r1 {
        align-items: center;
      }

      &.r2 {
        margin-top: 30px;

        @include for-tablet-landscape-up {
          margin-top: 5rem;
        }
      }

      &:not(.r1) .team-member {
        width: calc(100% / 3 - 20px);
        padding-bottom: 20px;
      }
    }

    &.join {
      flex-direction: column;
      margin-top: 80px;
      text-align: center;
      align-items: center;

      .title {
        margin-bottom: 26px;

        @include for-tablet-landscape-up {
          font-size: 64px;
          line-height: initial;
        }
      }
    }
  }

  .intro {
    @include for-tablet-landscape-up {
      width: 50%;
    }

    .title {
      @include for-tablet-landscape-up {
        font-size: 64px;
        line-height: initial;
      }
    }

    .description {
      margin-bottom: 0;
    }
  }

  .team-member {
    position: relative;
    border: 2px solid rgba($gray-dark, 0.8);
    border-radius: 6px;
    margin-top: 70px;

    @include for-tablet-landscape-up {
      border-color: #5A6066;
    }

    img {
      width: calc(100% - 50px);
      margin-left: 25px;
      margin-top: -33px;
    }

    .info {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 20px;
      background: linear-gradient(180deg, rgba(246, 246, 248, 0) 0%, #F6F6F8 58.85%);
      border-radius: 6px;
      width: calc(100% - 40px);

      .wrapper {
        color: #5a6066;

        .name {
          margin: 0;
          font-size: 28px;

          @include for-tablet-landscape-up {
            margin-bottom: 0;
            font-size: 32px;
          }
        }

        .description {
          margin: 0;
          margin-top: 4px;

          @include for-tablet-landscape-up {
            margin-top: 10px;
          }
        }
      }
    }

    &.member-1 {
      @include for-tablet-landscape-up {
        margin-top: 0;
        width: 48%;
        max-height: 400px;
      }

      img {
        @include for-tablet-landscape-up {
          float: right;
          width: 255px;
          height: 445px;
          margin: {
            top: -105px;
            right: 20px;
            bottom: 48px;
            left: 0;
          };
        }

        @include for-desktop-up {
          width: 300px;
          height: 453px;
          margin: {
            top: -6rem;
            right: 50px;
            bottom: 40px;
            left: 0;
          };
        }
      }

      .info .wrapper {
        @include for-tablet-landscape-up {
          width: 50%;
        }
      }
    }

    &.member-2 img {
      margin-bottom: 30px;

      @include for-tablet-landscape-up {
        float: right;
        width: 235px;
        height: 430px;
        margin: {
          top: -130px;
          right: 10px;
          bottom: 0;
        }
      }

      @include for-desktop-up {
        float: right;
        width: 330px;
        height: 485px;
        margin: {
          top: -130px;
          right: 30px;
          bottom: 0;
        }
      }
    }

    &.member-3 img,
    &.member-4 img {
      max-height: none;
      max-width: none;
      margin-bottom: 60px;
    }

    &.member-3 img {
      margin-top: -10px;
      margin-left: 10px;
      width: 100%;

      @include for-tablet-landscape-up {
        float: right;
        width: 300px;
        height: 328px;
        margin: 0 {
          top: -58px;
          right: -95px;
        }
      }

      @include for-desktop-up {
        float: right;
        width: 393px;
        height: 421px;
        margin: 0 {
          top: -86px;
          right: -125px;
        }
      }
    }

    &.member-4 img {
      margin-top: -13px;
      width: calc(100% - 8px);

      @include for-tablet-landscape-up {
        float: left;
        width: 305px;
        height: 358px;
        margin: 0 {
          left: -91px;
          top: -97px;
        };
      }

      @include for-desktop-up {
        float: left;
        width: 400px;
        height: 440px;
        margin: 0 {
          left: -110px;
          top: -126px;
        };
      }
    }
  }
}
