@use '../../variables' as *;

.qafana .file-input-wrapper {
  position: relative;

  input[type=file] {
    display: none;
  }

  .file-input {
    $button-width: 140px;

    cursor: pointer;
    padding-right: calc($button-width + 20px);
    width: calc(100% - $button-width - 20px - 20px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button.choose-file {
    position: absolute;
    top: 9px;
    right: 15px;
    cursor: pointer;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 4px 12px 4px 35px;
    font-weight: 700;
    background: #fff url(../../assets/icons/add.svg) no-repeat 8px 50%;
    background-size: 24px;
    font-size: 16px;
    color: #181818;

    @include for-tablet-landscape-up {
      box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.2);
    }
  }
}
