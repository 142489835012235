@use '../../../variables' as *;

.qafana .page.contact {
  .sections-wrapper {
    flex-direction: column;

    @include for-tablet-landscape-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .form-wrapper {
      @include for-tablet-landscape-up {
        width: 55%;
      }

      .title {
        @include for-tablet-landscape-up {
          font-size: 64px;
          line-height: initial;
        }
      }

      .feedback-wrapper {
        margin-top: 20px;
      }

      form {
        margin-top: 47px;

        @include for-tablet-landscape-up {
          padding: 32px;
          margin-top: 32px;
        }

        .group {
          @include for-tablet-landscape-up {
            margin-top: 32px;
          }

          label {
            @include for-tablet-landscape-up {
              font-size: 16px;
            }
          }
        }

        .form-title {
          font-size: 20px;
          color: #3d3d3d;

          @include for-tablet-landscape-up {
            font-size: 32px;
          }
        }

        .btn.submit {
          width: 180px;
          text-align: center;
        }
      }

      &.with-feedback form {
        margin-top: 30px;

        @include for-tablet-landscape-up {
          margin-top: 0;
        }
      }
    }

    .social-wrapper {
      margin-top: 50px;

      @include for-tablet-landscape-up {
        margin-top: 0;
        width: 40%;
      }

      .header {
        background-color: #19221B;
        color: $green-light;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 10px;

        .title {
          margin: 5px 0;
          font-weight: 500;
          font-size: 16px;
        }

        .icons {
          display: inline-flex;
          margin-top: 17px;

          img {
            width: 24px;
            height: 24px;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
