@use '../../../variables' as *;

.qafana .page.process-and-services {
  section.services {
    .grid {
      grid-template-columns: 100%;
      align-items: start;

      @include for-tablet-landscape-up {
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr auto auto;
      }
    }

    .background,
    .intro,
    .services-list,
    .cup-off-coffee,
    .info {
      grid-column: 1 / 2;
    }

    .background {
      grid-row: 1 / 2;
      width: 326%;
      margin-left: -33%;
      justify-self: center;

      @include for-tablet-portait-up {
        width: 300%;
        margin-left: -30%;
      }

      @include for-tablet-landscape-up {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        width: 116%;
        margin-left: 0;
      }

      &:not(.magnifier) {
        display: none;
        grid-row: 1 / 5;

        @include for-tablet-landscape-up {
          display: block;
        }
      }
    }

    .intro {
      grid-row: 1 / 2;
      text-align: center;
      align-self: center;
      margin-bottom: 17%;
      margin-right: 5%;

      @include for-tablet-landscape-up {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        justify-self: center;
        width: 37%;
        margin: 0 {
          left: 8%;
          top: -4%;
        };
      }

      .title {
        @include for-desktop-up {
          font-size: 64px;
        }
      }

      .description {
        color: #3A5A40;
        font-weight: 500;
      }
    }

    .services-list {
      grid-row: 2 / 3;
      margin: 0 {
        top: 40px;
      };
      padding: 0;

      @include for-tablet-landscape-up {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        margin-top: 12%;
      }

      .service {
        list-style-type: none;

        button {
          background: none;
          border: none;
          padding: 10px;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 12px;

          @include for-tablet-landscape-up {
            font-size: 12px;
            padding: 16px;
          }

          &.active {
            color: $blue;
          }
        }
      }
    }

    .cup-off-coffee {
      grid-row: 3 / 4;
      width: 80%;
      justify-self: center;
      align-self: center;
      margin-top: 25px;

      @include for-tablet-landscape-up {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        width: 100%;
        margin: 0 {
          right: -10%;
        };
      }
    }

    .info {
      grid-row: 4 / 5;
      margin-top: 30px;
      align-self: center;

      @include for-tablet-landscape-up {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        margin-top: 0;
      }

      .title {
        font-size: 20px;
        line-height: initial;

        @include for-tablet-landscape-up {
          font-size: 32px;
        }

        .dynamic {
          display: inline-block;
          border-bottom: 3px solid $gray-dark;
          padding-bottom: 5px;
          width: 178px;
          text-align: center;

          @include for-tablet-landscape-up {
            width: 240px;
          }
        }

        .suffix {
          margin-left: 20px;
        }
      }

      .description {
        margin-top: 40px;

        @include for-tablet-landscape-up {
          margin-top: 16px;
        }
      }
    }

    .cup-off-coffee,
    .info {
      @include for-tablet-landscape-up {
        margin-top: 7%;
      }
    }
  }

  section.process {
    .grid {
      grid-template-columns: 100%;
      align-items: start;

      @include for-tablet-landscape-up {
        grid-template-columns: 50% 50%;
      }
    }

    .intro,
    .animated-image,
    .steps-wrapper {
      grid-column: 1 / 2;
    }

    .intro {
      grid-row: 1 / 2;

      @include for-tablet-landscape-up {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      .title {
        font-size: 40px;

        @include for-tablet-landscape-up {
          font-size: 64px;
          line-height: initial;
        }
      }

      .description {
        margin-top: 25px;
        color: $gray-dark;
        font-size: 32px;
        line-height: 48px;

        @include for-tablet-landscape-up {
          margin: 0 {
            top: 20px;
          };
        }
      }
    }

    .animated-image {
      grid-row: 2 / 3;
      justify-self: center;
      align-self: center;
      width: 120%;
      z-index: -1;
      user-select: none;

      @include for-tablet-landscape-up {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        width: 126%;
        margin: 0 {
          right: -7%;
        };
      }
    }

    .steps-wrapper {
      grid-row: 3 / 4;
      margin-top: 15px;

      @include for-tablet-landscape-up {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        margin-top: 56px;
      }

      .indicator {
        font-weight: 700;
        font-size: 32px;

        .total-steps {
          display: none;
          font-size: 20px;
          margin-left: 7px;
          color: $gray;

          @include for-tablet-landscape-up {
            display: inline-block;
          }
        }
      }

      .steps {
        padding: 0;
        margin: 0;

        .step {
          list-style-type: none;

          &:not(.active) {
            display: none;
          }

          .title {
            color: #5a6066;
            font-size: 20px;

            @include for-tablet-landscape-up {
              margin-bottom: 0;
            }
          }

          .description {
            height: 190px;
            font-size: 17px;
            line-height: 24px;
            user-select: none;

            @include for-tablet-portait-up {
              height: 110px;
            }

            @include for-tablet-landscape-up {
              margin-top: 16px;
              user-select: auto;
              height: 150px;
            }

            @include for-desktop-up {
              height: 110px;
            }
          }
        }
      }

      .navigator {
        padding: 0;
        margin: 0;
        justify-content: space-between;

        li {
          list-style-type: none;
          width: 8%;

          button {
            background: rgba($gray-dark, 0.2);
            border: none;
            border-radius: 6px;
            height: 8px;
            width: 100%;
            padding: 0;
            overflow: hidden;

            &:hover {
              background: rgba($gray-dark, 0.7);
            }

            &.active {
              background: $gray-dark;
            }

            span.progress {
              display: block;
              height: 100%;
              background-color: $green;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }
}
