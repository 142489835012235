@use '../../../../variables' as *;

.qafana .page.home section.technology {
  .technology-list-wrapper {
    .os-scrollbar {
      --os-size: 6px;
      --os-padding-perpendicular: 0;
      --os-padding-axis: 0;
      --os-track-bg: rgba(113, 120, 126, 0.16);
      --os-track-bg-hover: rgba(113, 120, 126, 0.16);
      --os-track-bg-active: rgba(113, 120, 126, 0.16);
      --os-track-border-radius: 20px;
      --os-handle-border-radius: 20px;
      --os-handle-bg: #3A5A40;

      display: flex;
      justify-content: center;

      .os-scrollbar-track {
        width: var(--base-width);
      }
    }
  }

  .technology-list {
    margin: 0;
    padding: 0;
    margin-top: 30px;

    li {
      list-style-type: none;

      img {
        width: 290px;
        height: 140px;
        border: 0px groove #5a6066;
        border-style: groove;
        border-radius: 6px;
        margin: 5px 11px;
        margin-bottom: 20px;
        object-fit: contain;
        user-select: none;

        @include for-tablet-landscape-up {
          margin: {
            left: 18px;
            right: 18px;
          };
        }
      }
    }
  }
}
