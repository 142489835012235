@use '../../variables' as *;
@use 'sass:map';

.qafana {
  .main {
    margin-top: 90px;

    @include for-tablet-landscape-up {
      margin-top: 0;
    }
  }

  header {
    padding: 30px 0 20px 0;
    color: $gray-dark;
    position: fixed;
    width: 100%;
    z-index: 100;
    background-color: #f3f3f4;

    @include for-tablet-landscape-up {
      position: inherit;
      padding-bottom: 0;
      background-color: $gray-light;
    }

    .logo-wrapper {
      flex-grow: 1;

      a {
        display: inline-block;
        text-decoration: none;
      }
    }

    .toggle-menu {
      width: 24px;
      height: 24px;
      border: none;
      background-color: transparent;
      background-repeat: no-repeat;

      @include for-tablet-landscape-up {
        display: none;
      }

      &.open {
        background-image: url(./burger.svg);
      }

      &.close {
        background-image: url(./cross.svg);
      }
    }

    nav {
      display: none;
      font-weight: 500;
      flex-grow: 1;
      justify-content: flex-end;
      flex-wrap: wrap;
      align-content: center;
      padding-top: 20px;

      @include for-tablet-landscape-up {
        display: inline-flex !important;
        padding-top: 0;
        font-size: 18px;
      }

      a {
        text-decoration: none;
        color: $gray-dark;

        &:hover,
        &.active {
          border-bottom: 1px dotted rgba($gray-dark, 0.7);
        }

        &.active {
          color: $green-real;
          border-color: $green-real;
        }
      }

      li {
        list-style-type: none;
        padding: 5px 24px;

        @include for-tablet-landscape-up {
          padding: 0 0 0 24px;
        }
      }
    }

    @include for-tablet-landscape-up {
      .break {
        display: none;
      }
    }
  }
}

body.overlay-menu-visible {
  overflow: hidden;

  .qafana > .main{
    filter: blur(2px);

    @include for-tablet-landscape-up {
      filter: blur(0px);
    }
  }

  .qafana header {
    padding-bottom: 0;
    background-color: $gray-light;
  }

  .overlay-menu {
    position: absolute;
    z-index: 99;
    background: rgba($gray-light, 0.7);
    top: 85px;
    left: 0;

    @include for-tablet-landscape-up {
      display: none;
    }

    .divider {
      height: 0;
      border-style: none;
      border-top: 1px solid #040404;
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 25px 0 0 0;
      font-size: 16px;
      background: $gray-light;
      height: max-content;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(#5A6066, 0.2);

      li {
        width: base-width-fluid(map.get($margins, 'mobile'), 32px);
        list-style-type: none;
        display: grid;
        margin-bottom: 7px;

        a {
          color: #484E53;
          text-decoration: none;
          font-weight: 500;
          padding: 8px 16px;
          border-radius: 60px;

          &.active {
            background-color: #eceeed;
            color: $green-real;
            border: 1px solid #b7c8bf;
          }
        }
      }
    }
  }
}
