@use '../../../variables' as *;

.qafana .page.career {
  h2.title {
    @include for-tablet-landscape-up {
      font-size: 64px;
      line-height: initial;
    }
  }

  > .wrapper {
    margin-top: 40px;
    flex-direction: column;
    align-items: center;

    @include for-tablet-landscape-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .form-wrapper {
      border: 1px solid rgba($gray-dark, 0.15);
      background: #fff;
      box-shadow: 0px 2px 6px rgba($gray-dark, 0.1);
      border-radius: 20px;
      padding: 20px;

      @include for-tablet-landscape-up {
        padding: 30px;
      }

      @include for-tablet-landscape-up {
        width: 57%;
      }

      .title {
        font-size: 32px;
        line-height: 50px;;
      }

      .positions {
        padding: 0;
        margin: 40px 0 30px 0;

        li.position {
          display: inline-block;
          list-style-type: none;
          margin: 10px 10px 0 0;

          button {
            background: none;
            border: none;
            padding: 5px 5px 3px 5px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 12px;
            color: $green-dark;
          }

          &.active {
            border-bottom: 1px solid $green;

            button {
              color: $green-real;
            }
          }
        }
      }

      form {
        input[type=text],
        input[type=email] {
          border-color: #e8e8e8;
        }

        button[type=submit] {
          min-width: 180px;
        }

        .group.privacy-policy {
          input[type=checkbox] + label {
            margin-right: 8px;
          }

          label {
            @include for-tablet-landscape-up {
              font-size: 16px;
              color: #5A6066;
            }
          }
        }
      }
    }

    .benefits-wrapper {
      width: 100%;
      margin-top: 20px;
      background-image: url(./shy-bean.svg);
      background-repeat: no-repeat;
      background-position: calc(50%) top;
      background-size: 21em;
      padding-top: 500px;

      @include for-tablet-landscape-up {
        width: 43%;
        margin-top: 0;
        background-size: 24em;
        padding-top: 560px;
        padding-left: 32px;
      }

      @include for-desktop-up {
        background-size: 29em;
        padding-top: 650px;
      }

      .benefits {
        padding: 0;
        margin: 0;
        width: 100%;

        li {
          display: flex;
          list-style-type: none;
          border: 1px solid rgba($green-dark, 0.8);
          border-radius: 6px;
          margin-bottom: 20px;
          padding: 20px 12px;
          box-shadow: 0px 0px 5px 2px rgba(212, 212, 212, 0.53);
          background: #ffffff;

          img {
            max-width: 60px;
          }

          .info {
            margin-left: 20px;

            .title {
              margin: 0;
              text-transform: uppercase;
              font-size: 12px;
              color: #3d3d3d;
            }

            .description {
              font-weight: 500;
              margin-top: 6px;
              margin-bottom: 0;
              color: $gray;
            }
          }
        }
      }
    }
  }
}
