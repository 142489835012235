@use '../../variables' as *;

.qafana {
  .btn {
    display: inline-block;
    border: none;
    background: $blue;
    border-radius: 60px;
    color: $gray-light;
    padding: 12px 28px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;

    img {
      width: 24px;
      height: 24px;
      user-select: none;
    }

    &:has(img) {
      line-height: 0;
    }

    &.loading {
      @keyframes spinner-rotation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(359deg);
        }
      }

      img.spinner {
        animation: spinner-rotation 1s infinite linear;
      }
    }
  }

  .btn:hover {
    box-shadow: 0px 1px 4px 2px rgba($gray, 0.5);
  }

  .btn:active {
    box-shadow: 0px 0px 4px 2px rgba($gray, 0.4);
  }
}
