// Layout

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-portait-only {
  @media (min-width: 600px) and (max-width: 899px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-tablet-landscape-only {
  @media (min-width: 900px) and (max-width: 1199px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-desktop-only {
  @media (min-width: 1200px) and (max-width: 1439px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1440px) { @content; }
}

$margins: (
  "mobile": 32px,
  "desktop": 100px,
);

$base-width-max: 1240px;

@function base-width-fluid($margins, $add: 0px) {
  @return calc(100% - 2 * $margins + $add);
}

// Colors

$black: #040404;
$gray-light: #f6f6f8;
$gray: #71787e;
$gray-dark: #29322c;
$green-light: #def3e4;
$green: #8dc59d;
$green-dark: #3a5a40;
$green-real: #0E9C59;
$red: #cd4d49;
$blue: #1e96fc;
